import { useNavigate } from "react-router-dom";
import './style.css';


const Header = () => {
    const navigate = useNavigate();
    return (
        <>
            <header>
                <div className="header">
                    <span onClick={()=> navigate("/")}>MIAC TECH</span>
                </div>
            </header>
        </>
    );
}

export default Header;
