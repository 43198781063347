import React, { useEffect, useState } from "react";
import Header from "../../components/header/comp";
import Footer from "../../components/footer/comp";

export default function HomeView(){
    const [loaded, setLoaded] = useState(false);
    const handleDownload = () => {
        try{
            setLoaded(true);
            const link = document.createElement('a');
            link.href = '/cert.crt';
            link.download = 'cert.crt';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }catch{
            setLoaded(false);
        }
    };

    document.title = "Главная";
    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])    
    return (
        <>
            <Header />
            <main className="gap clm">
                <div className="welcome full centered clm">
                    <h1>Информация о текущем статусе разработки</h1>
                    <p>В скором времени на данной странице будет размещена актуальная информация.</p>
                </div>
                <div>
                    <h2>Рекомендации до официального запуска системы</h2>
                    <p>В связи с использованием собственного удостоверяющего центра, настоятельно рекомендуем установить сертификат удостоверяющего центра для обеспечения корректного функционирования системы.</p>
                    {loaded ? (
                        <>
                        <button disabled>Благодарим за загрузку</button>
                        <p className="mini">Для обеспечения безопасного подключения, пожалуйста, внесите данный сертификат в настройки вашего браузера, что позволит избежать уведомлений о проблемах с SSL.</p>
                        </>
                    ) : (
                        <button onClick={handleDownload}>Скачать сертификат удостоверяющего центра</button>
                    )}
                </div>
            </main>
            <Footer />
        </>
    )
}