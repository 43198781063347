import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import E404View from './views/notFound/view';
import HomeView from './views/home/view';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<HomeView />} />
        <Route path='*' element={<E404View />} />
      </Routes>
  </Router>
  );
}

export default App;
