import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/comp";
import Footer from "../../components/footer/comp";

export default function E404View(){
   const navigate = useNavigate();
   document.title = "Страница не найдена";
    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])    
    return (
        <>
        <Header />
            <main className="centered clm">
                <h1 className="big">404</h1>
                <p className="nomarg">Страница не найдена или не существовала вовсе</p>
                <p className="nomarg mini">Проверьте правильность ссылки</p>
                <button onClick={()=> navigate("/")}><i className="fa fa-external-link" aria-hidden="true"></i><span>Вернуться на главную</span></button>
            </main>
        <Footer />
        </>
    )
}