import './style.css';
import packageJson from '../../../package.json';

const Footer = () =>{
    return(
        <>
        <footer>
            <div className="footer">
                <p>v{packageJson.version}</p>
                <p>{packageJson.author} {new Date().getFullYear()}</p>
            </div>
        </footer>
        </>
    )
}


export default Footer
